<template>
  <ui-component-modal
    modalTitle="Edit settings"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="saveOptionLocation"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <table v-if="mOption" class="table is-fullwidth is-striped">
        <tbody>
          <tr>
            <td>Category</td>
            <td>
              <div class="control">
                <label
                  v-for="(category, index) in categories"
                  :key="index"
                  class="radio"
                >
                  <input
                    type="radio"
                    v-model="mOption.Location.CategoryId"
                    :checked="mOption.Location.CategoryId === category.Type"
                    :value="category.Type"
                    name="category"
                  />
                  {{ category.Name }}
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td>Export group</td>
            <td>
              <div class="select">
                <select v-model="mOption.Location.ExportgroupId">
                  <option
                    v-for="exportgroup in exportgroups"
                    :key="exportgroup.Id"
                    :value="exportgroup.Id"
                    :selected="
                      mOption.Location.ExportgroupId === exportgroup.Id
                    "
                  >
                    {{ exportgroup.Name }}
                  </option>
                </select>
              </div>
            </td>
          </tr>
          <tr>
            <td>Required item</td>
            <td>
              <div class="select">
                <select v-model="mOption.Location.RequiredItem">
                  <option
                    :value="0"
                    :selected="mOption.Location.RequiredItem === 0"
                  >
                    Not applicable
                  </option>
                  <option
                    :value="1"
                    :selected="mOption.Location.RequiredItem === 1"
                  >
                    Optional
                  </option>
                  <option
                    :value="2"
                    :selected="mOption.Location.RequiredItem === 2"
                  >
                    Required
                  </option>
                </select>
              </div>
            </td>
          </tr>

          <tr>
            <td>Hours until reservation</td>
            <td>
              <div
                class="tabs is-toggle is-inline-flex is-small is-pulled-left"
              >
                <ul>
                  <li
                    :class="{
                      'is-active':
                        mOption.Location.HoursBeforeReservation === 0,
                    }"
                  >
                    <a @click="mOption.Location.HoursBeforeReservation = 0">
                      <span>Off</span>
                    </a>
                  </li>
                  <li
                    :class="{
                      'is-active': mOption.Location.HoursBeforeReservation > 0,
                    }"
                  >
                    <a @click="mOption.Location.HoursBeforeReservation = 1">
                      <span>On</span>
                    </a>
                  </li>
                </ul>
              </div>

              <div
                v-if="mOption.Location.HoursBeforeReservation > 0"
                class="has-padding-left"
              >
                <div class="select">
                  <select v-model="mOption.Location.HoursBeforeReservation">
                    <option
                      v-for="(hour, index) in hoursAlt"
                      :key="index"
                      :value="hour"
                    >
                      {{ hour }}
                    </option>
                  </select>
                </div>
              </div>
            </td>
          </tr>

          <tr>
            <td>Max. amount per reservation</td>
            <td>
              <div
                class="tabs is-toggle is-inline-flex is-small is-pulled-left"
              >
                <ul>
                  <li
                    :class="{
                      'is-active': mOption.Location.MaxAmount === 0,
                    }"
                  >
                    <a @click="mOption.Location.MaxAmount = 0">
                      <span>Off</span>
                    </a>
                  </li>
                  <li
                    :class="{
                      'is-active': mOption.Location.MaxAmount > 0,
                    }"
                  >
                    <a @click="mOption.Location.MaxAmount = 1">
                      <span>On</span>
                    </a>
                  </li>
                </ul>
              </div>

              <div v-if="mOption.Location.MaxAmount > 0">
                <InputNumeric
                  class="inputNumeric"
                  :isNumeric="true"
                  v-model.number="mOption.Location.MaxAmount"
                />
              </div>
            </td>
          </tr>

          <tr>
            <td>Min. particpants</td>
            <td>
              <div
                class="tabs is-toggle is-inline-flex is-small is-pulled-left"
              >
                <ul>
                  <li
                    :class="{
                      'is-active': mOption.Location.MinimumParticipants === 0,
                    }"
                  >
                    <a @click="mOption.Location.MinimumParticipants = 0">
                      <span>Off</span>
                    </a>
                  </li>
                  <li
                    :class="{
                      'is-active': mOption.Location.MinimumParticipants > 0,
                    }"
                  >
                    <a @click="mOption.Location.MinimumParticipants = 1">
                      <span>On</span>
                    </a>
                  </li>
                </ul>
              </div>

              <div v-if="mOption.Location.MinimumParticipants > 0">
                <InputNumeric
                  class="inputNumeric"
                  :isNumeric="true"
                  v-model.number="mOption.Location.MinimumParticipants"
                />
              </div>
            </td>
          </tr>

          <tr>
            <td>Max. participants</td>
            <td>
              <div
                class="tabs is-toggle is-inline-flex is-small is-pulled-left"
              >
                <ul>
                  <li
                    :class="{
                      'is-active': mOption.Location.MaximumParticipants === 0,
                    }"
                  >
                    <a @click="mOption.Location.MaximumParticipants = 0">
                      <span>Off</span>
                    </a>
                  </li>
                  <li
                    :class="{
                      'is-active': mOption.Location.MaximumParticipants > 0,
                    }"
                  >
                    <a @click="mOption.Location.MaximumParticipants = 1">
                      <span>On</span>
                    </a>
                  </li>
                </ul>
              </div>

              <div v-if="mOption.Location.MaximumParticipants > 0">
                <InputNumeric
                  class="inputNumeric"
                  :isNumeric="true"
                  v-model.number="mOption.Location.MaximumParticipants"
                />
              </div>
            </td>
          </tr>

          <tr>
            <td>Min. duration</td>
            <td>
              <div
                class="tabs is-toggle is-inline-flex is-small is-pulled-left"
              >
                <ul>
                  <li
                    :class="{
                      'is-active':
                        mOption.Location.MinimumReservationDuration === 0,
                    }"
                  >
                    <a @click="mOption.Location.MinimumReservationDuration = 0">
                      <span>Off</span>
                    </a>
                  </li>
                  <li
                    :class="{
                      'is-active':
                        mOption.Location.MinimumReservationDuration > 0,
                    }"
                  >
                    <a
                      @click="mOption.Location.MinimumReservationDuration = 60"
                    >
                      <span>On</span>
                    </a>
                  </li>
                </ul>
              </div>

              <div
                v-if="mOption.Location.MinimumReservationDuration > 0"
                class="has-padding-left"
              >
                <div class="select">
                  <select v-model="mOption.Location.MinimumReservationDuration">
                    <option
                      v-for="(hour, index) in hours"
                      :key="index"
                      :value="hour"
                    >
                      {{ hour / 60 }}
                    </option>
                  </select>
                </div>
              </div>
            </td>
          </tr>

          <tr>
            <td>Max. duration</td>
            <td>
              <div
                class="tabs is-toggle is-inline-flex is-small is-pulled-left"
              >
                <ul>
                  <li
                    :class="{
                      'is-active':
                        mOption.Location.MaximumReservationDuration === 0,
                    }"
                  >
                    <a @click="mOption.Location.MaximumReservationDuration = 0">
                      <span>Off</span>
                    </a>
                  </li>
                  <li
                    :class="{
                      'is-active':
                        mOption.Location.MaximumReservationDuration > 0,
                    }"
                  >
                    <a
                      @click="mOption.Location.MaximumReservationDuration = 60"
                    >
                      <span>On</span>
                    </a>
                  </li>
                </ul>
              </div>

              <div
                v-if="mOption.Location.MaximumReservationDuration > 0"
                class="has-padding-left"
              >
                <div class="select">
                  <select v-model="mOption.Location.MaximumReservationDuration">
                    <option
                      v-for="(hour, index) in hours"
                      :key="index"
                      :value="hour"
                    >
                      {{ hour / 60 }}
                    </option>
                  </select>
                </div>
              </div>
            </td>
          </tr>

          <tr>
            <td>Time selectable</td>
            <td>
              <div class="select">
                <select v-model="mOption.Location.TimeSelectable">
                  <option
                    :value="0"
                    :selected="mOption.Location.TimeSelectable === 0"
                  >
                    Not applicable
                  </option>
                  <option
                    :value="1"
                    :selected="mOption.Location.TimeSelectable === 1"
                  >
                    Location only
                  </option>
                  <option
                    :value="2"
                    :selected="mOption.Location.TimeSelectable === 2"
                  >
                    Location &amp; booker
                  </option>
                </select>
              </div>
            </td>
          </tr>
          <tr>
            <td>Available from</td>
            <td>
              <div class="select">
                <select v-model="mOption.Location.AvailableStart">
                  <option
                    v-for="(time, index) in timeSlots"
                    :key="index"
                    :value="time"
                  >
                    {{ time | minutesToTime }}
                  </option>
                </select>
              </div>
            </td>
          </tr>
          <tr>
            <td>Available until</td>
            <td>
              <div class="select">
                <select v-model="mOption.Location.AvailableEnd">
                  <option
                    v-for="(time, index) in timeSlots"
                    :key="index"
                    :value="time"
                  >
                    {{ time | minutesToTime }}
                  </option>
                </select>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import optionProvider from '@/providers/option'

export default {
  components: {
    InputNumeric: () => import('@/components/UI/Form/InputNumeric'),
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },

    exportgroups: {
      type: Array,
      default: function () {
        return []
      },
    },
  },

  data() {
    return {
      categories: [],
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      mOption: null,
      hours: [60, 120, 180, 240, 300, 360, 420, 480, 540, 600, 660, 720],
      hoursAlt: [1, 2, 4, 8, 12, 16, 24, 36, 48, 72, 96, 144],
    }
  },

  computed: {
    ...mapState('optionStore', ['option']),

    timeSlots() {
      let slots = []
      let min = 0
      let max = 1440
      let minutes = 30

      let i = min
      while (i <= max) {
        slots.push(i)
        i = i + minutes
      }
      return slots
    },
  },

  created() {
    this.mOption = JSON.parse(JSON.stringify(this.option))

    this.categories.push({ Id: 1, Type: 'FB', Name: 'Food & beverage' })
    this.categories.push({ Id: 2, Type: 'AV', Name: 'Audio visual' })
    this.categories.push({ Id: 3, Type: 'Parking', Name: 'Parking' })
    this.categories.push({ Id: 4, Type: 'Other', Name: 'Other' })
  },

  methods: {
    ...mapMutations('optionStore', ['setOption']),

    saveOptionLocation() {
      let self = this
      if (!self.isSaving) {
        self.isSaving = true

        optionProvider.methods
          .saveLocationOptionSettings(self.mOption.Location)
          .then((response) => {
            self.setOption(response.data)

            // Send message to display toast on screen
            EventBus.$emit('showToast', {
              type: 'info',
              message: 'Option updated',
            })

            self.onClickCancel()
          })
          .catch((e) => {
            self.isSavingError = true
          })
          .finally(() => {
            self.isSaving = false
          })
      }
    },
  },
}
</script>
